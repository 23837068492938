"use client";

import React from "react";
import Image from "next/image";
import "./HomeFormsection.css";
import Link from "next/link";
// import dynamic from "next/dynamic";
// const ContactForm = dynamic(() => import("../..app/contact-us/ContactForm"));
import  ContactForm from "../../app/contact-us/ContactForm"


const HomeFormsection = () => {
  return (
    <section className="common_section form_section">
      <div className="container">
        <div className="form_sectioninner">
          <div className="info">
            <h3 className="info_hd">
              GET STARTED WITH DIGITAL <br />HEALTH HOUSE TODAY
            </h3>
            <p className="info_desc">
              For inquiries, support, or collaboration opportunities, reach
              <br />
              out to us. We're here to assist you with any questions or
              <br /> concerns you may have.
            </p>
            <div className="contact">
              <div className="emailbx">
                <span>
                  <svg
                    width="23"
                    height="22"
                    viewBox="0 0 23 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.9764 3.66284H5.14307C4.41372 3.66284 3.71425 3.95257 3.19852 4.4683C2.6828 4.98402 2.39307 5.6835 2.39307 6.41284V15.5795C2.39307 16.3089 2.6828 17.0083 3.19852 17.5241C3.71425 18.0398 4.41372 18.3295 5.14307 18.3295H17.9764C18.7057 18.3295 19.4052 18.0398 19.9209 17.5241C20.4367 17.0083 20.7264 16.3089 20.7264 15.5795V6.41284C20.7264 5.6835 20.4367 4.98402 19.9209 4.4683C19.4052 3.95257 18.7057 3.66284 17.9764 3.66284ZM17.3622 5.49618L11.5597 9.85034L5.75723 5.49618H17.3622ZM17.9764 16.4962H5.14307C4.89995 16.4962 4.66679 16.3996 4.49489 16.2277C4.32298 16.0558 4.2264 15.8226 4.2264 15.5795V6.64201L11.0097 11.7295C11.1684 11.8485 11.3614 11.9128 11.5597 11.9128C11.7581 11.9128 11.9511 11.8485 12.1097 11.7295L18.8931 6.64201V15.5795C18.8931 15.8226 18.7965 16.0558 18.6246 16.2277C18.4527 16.3996 18.2195 16.4962 17.9764 16.4962Z"
                      fill="#174388"
                    />
                  </svg>
                </span>
                <p>
                  <a href="mailto:support@hdh-c.com">support@hdh-c.com</a>
                </p>
              </div>
              <div className="emailbx">
                <span>
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.75395 3.32951H7.49562L8.70145 6.34451L6.76395 7.63618C6.64985 7.7123 6.55631 7.81541 6.49162 7.93636C6.42693 8.0573 6.3931 8.19235 6.39312 8.32951C6.39562 8.40784 6.39312 8.33034 6.39312 8.33034V8.34784C6.39361 8.38511 6.39528 8.42235 6.39812 8.45951C6.40312 8.52784 6.41145 8.62034 6.42728 8.73451C6.45978 8.95951 6.52228 9.26951 6.64395 9.63451C6.88895 10.3678 7.36895 11.317 8.30395 12.252C9.23895 13.187 10.1881 13.667 10.9206 13.912C11.2865 14.0337 11.5956 14.0953 11.8223 14.1287C11.9502 14.1465 12.079 14.1577 12.2081 14.162L12.219 14.1628H12.2256C12.2256 14.1628 12.319 14.1578 12.2265 14.1628C12.3812 14.1628 12.5328 14.1196 12.6644 14.0382C12.796 13.9568 12.9023 13.8404 12.9715 13.702L13.5298 12.5853L17.2264 13.202V16.802C15.4673 17.0562 10.7156 17.307 6.98228 13.5737C3.24895 9.84034 3.49895 5.08784 3.75395 3.32951ZM8.12062 8.73451L9.62645 7.73117C9.94457 7.51898 10.18 7.20386 10.2934 6.83865C10.4067 6.47343 10.391 6.08036 10.249 5.72534L9.04312 2.71034C8.91938 2.4011 8.70581 2.13602 8.42998 1.94931C8.15415 1.7626 7.8287 1.66282 7.49562 1.66284H3.71062C2.95312 1.66284 2.24062 2.18868 2.11645 3.01034C1.83312 4.87868 1.44895 10.397 5.80395 14.752C10.1589 19.107 15.6773 18.722 17.5456 18.4395C18.3673 18.3145 18.8931 17.6028 18.8931 16.8453V13.202C18.8932 12.8075 18.7533 12.4257 18.4983 12.1247C18.2433 11.8236 17.8898 11.6227 17.5006 11.5578L13.804 10.942C13.4524 10.8833 13.0912 10.9389 12.7736 11.1006C12.4559 11.2623 12.1984 11.5215 12.0389 11.8403L11.7506 12.4178C11.6487 12.3927 11.5477 12.3635 11.4481 12.3303C10.9315 12.1587 10.214 11.8053 9.48228 11.0737C8.75062 10.342 8.39728 9.62451 8.22562 9.10701C8.18463 8.98468 8.14986 8.86036 8.12145 8.73451H8.12062Z"
                      fill="#174388"
                    />
                  </svg>
                </span>
                <p>
                  <Link href="tel:+966 11 4020576">+966 11 4020576</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="form-container">
          <h2>LET'S GET IN TOUCH</h2>
          <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeFormsection;
