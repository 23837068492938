/** @format */

"use client";
import CustomButton from "@/component/Buttons/CustomButton";
import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { apiService } from "@/services/api.services";
import ReCAPTCHA from "react-google-recaptcha";
import { useRouter } from "next/navigation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";

const ContactForm = () => {
  const router = useRouter();
  const recaptchaRef = useRef(null);
  const [selectedCountry, setSelectedCountry] = useState("SA");
  const [isSuccess, setIsSuccess] = useState(false);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    company: Yup.string().required("Company is required"),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .test("phone-valid", "Phone number is not valid", (value) =>
        isValidPhoneNumber(value, selectedCountry)
      ),
    message: Yup.string().required("Message is required"),
    recaptcha: Yup.boolean()
      .oneOf([true], "Field must be checked")
      .required(""),
  });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      company: "",
      email: "",
      phoneNumber: "",
      message: "",
      recaptcha: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      let payload = {
        data: {
          first_name: values.firstName,
          email: values.email,
          company_name: values.company,
          phone_number: values.phoneNumber,
          message: values.message,
        },
      };

      try {
        const response = await apiService.postContactUsDetails(payload);
        if (response.status === 200) {
          apiService.sendThankyouMail({ data: payload.data });
          setIsSuccess(true);
          setTimeout(() => {
            router.push("/thankyou");
          }, 900);
        }
        return response;
      } catch (error) {
        console.log(error);
      }
    },
  });
  const onChange = (value) => {
    if (value) {
      formik.setFieldValue("recaptcha", true);
    } else {
      formik.setFieldValue("recaptcha", false);
    }
  };

  const sitekey = "6LcALQcqAAAAAEupNgGR2dJNxY03aarTBXjgSqZA";

  return (
    <form onSubmit={formik.handleSubmit} className="contactform_container">
      <div className="contactform_container_row">
        <TextInput
          label={"Name"}
          placeholder={"Enter your name"}
          id="firstName"
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.touched.firstName && formik.errors.firstName}
        />
         <TextInput
          label={"Email"}
          placeholder={"Enter your email"}
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.touched.email && formik.errors.email}
        />
        
      </div>
      <div className="contactform_container_row contactform_container_rowwithphone">
       

        <div className="custom_number_container">
          <p className="input-label">Phone Number</p>
          <PhoneInput
            country={selectedCountry.toLowerCase()}
            name="phoneNumber"
            id="phoneNumber"
            inputProps={{
              name: "phoneNumber",
              id: "phoneNumber",
            }}
            copyNumbersOnly
            enableSearch
            isValid
            value={formik.values.phoneNumber}
            onChange={(value, country) => {
              formik.setFieldValue("phoneNumber", value);
              setSelectedCountry(country.countryCode.toUpperCase());
            }}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <p className="error-message">{formik.errors.phoneNumber ?? ""}</p>
          )}
        </div>
        {/* <TextInput
          label={"Nature of inquiry"}
          placeholder={"Enter your Nature of inquiry"}
          id="company"
          name="company"
          value={formik.values.company}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.touched.company && formik.errors.company}
        /> */}
        
        <SelectInput
          label={"Nature of inquiry"}
          placeholder={"Enter your Nature of inquiry"}
          name="company"
          value={formik.values.company}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.touched.company && formik.errors.company}
          options={[
            { value: "Nature of inquiry", label: "Nature of inquiry" },
            { value: "General inquiry", label: "General inquiry" },
           
          ]}
        />
     
      </div>
      <div className="contactform_container_row contactform_container_rowtextarea">
        <TextInput
          label={"Message"}
          placeholder={"Write your message"}
          id="message"
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.touched.message && formik.errors.message}
          multi={true}
        />
      </div>
      <div className="contactform_container_row contactform_container_row_bottom">
        <div className="contactform_recaptcha_container">
          <ReCAPTCHA sitekey={sitekey} ref={recaptchaRef} onChange={onChange} />
          {formik.touched.recaptcha && formik.errors.recaptcha && (
            <p className="error-message">{formik.errors.recaptcha ?? ""}</p>
          )}
        </div>
        <div>
          <CustomButton
            isLoading={formik.isSubmitting}
            Text={isSuccess ? "Submitted" : "Submit"}
            PrimaryColorBtn
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};
const TextInput = ({
  errorText,
  label,
  placeholder,
  value,
  onChange,
  multi,
  ...props
}) => {
  if (multi) {
    return (
      <div className="input-container">
        <label htmlFor="input-field">{label}</label>
        <textarea
          {...props}
          type="text"
          id="input-field"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          cols="30"
          rows="5"
        />
        {errorText && <p className="error-message">{errorText}</p>}
      </div>
    );
  }
  return (
    <div className="input-container">
      <label htmlFor="input-field">{label}</label>
      <input
        {...props}
        type="text"
        id="input-field"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {errorText && <p className="error-message">{errorText}</p>}
    </div>
  );
};

const SelectInput = ({
  errorText,
  label,
  value,
  onChange,
  options,
  ...props
}) => {
  return (
    <div className="input-container">
      <label htmlFor="select-field">{label}</label>
      <select
        {...props}
        id="select-field"
        value={value}
        onChange={onChange}
      >
        <option value="" label="Nature of inquiry">
          Nature of inquiry
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errorText && <p className="error-message">{errorText}</p>}
    </div>
  );
};

export default ContactForm;
